import logo from '../images/logo.png';
export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <img src={logo} style={{ float: "left" }} alt="logo_hoaxuan" />
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                Liên hệ
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
